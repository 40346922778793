import React from "react"
import styled from "styled-components"

import Branch1_img from "../../static/branches/branch1.png"
import Branch1_img_480 from "../../static/branches/branch1-480.png"
const Container = styled.div`
  .branch-background img {
    width: 100vw;
    display: block;
  }
  .branch-background p {
    font-size: 2.3vw;
    position: absolute;
    font-weight: 300;
    width: 60vw;
    left: 50vw;
    margin-left: -30vw;
    margin-top: 7vw;
  }

  .branch-background p span {
    font-weight: bold;
  }

  .show-480 {
    display: none !important;
  }

  @media screen and (max-width: 480px) {
    .branch-background p {
      font-size: 7vw;
      width: 80vw;
      margin-left: -40vw;
      margin-top: 9vw;
    }

    .show-480 {
      display: block !important;
    }

    .hide-480 {
      display: none !important;
    }
  }
`

const branch1 = () => {
  return (
    <Container>
      <div className="branch-background">
        <p>
          공간샘<span> 지점현황</span>
        </p>
        <img className="show-480" src={Branch1_img_480} />
        <img className="hide-480" src={Branch1_img} />
      </div>
    </Container>
  )
}

export default branch1
