import React, { useState } from "react"
import styled from "styled-components"
import { faAngleUp } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import Searchbtn from "../../static/search.svg"
import Topbtn from "../../static/topbutton.svg"
import Branch2_1 from "./branch2-1"

declare global {
  interface Window {
    kakao: any
  }
}

const Container = styled.div`
  background-color: white;
  padding: 2vw 0 2vw;
  position: relative;

  .search-branch {
    height: 4vw;
    width: 30vw;
    margin-right: 2vw;
    padding: 0 1.2vw;
    border: none;
    box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.16);
  }

  .search-branch:focus {
    outline: none;
  }

  .searchform {
    position: absolute;
    z-index: 99;
    display: flex;
    width: 60vw;
    justify-content: center;
    left: 50vw;
    margin-left: -30vw;
    margin-top: -14.5vw;
    font-size: 1.5vw;
  }

  .searchform img {
    width: 11.5vw;
    cursor: pointer;
  }

  #topbtn {
    width: 4vw;
    height: 4vw;
    position: fixed;
    display: none;
    bottom: 0;
    right: 0;
    text-align: center;
    background-color: #d96e62;
    font-size: 1.2vw;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 100px;
    color: #fff;
    cursor: pointer;
    margin: 0px 14vw 5.4vw;
    padding-bottom: 0.5%;
  }

  .appear {
    display: block !important;
  }
  .disappear {
    display: none !important;
  }

  @media screen and (max-width: 480px) {
    .search-branch {
      width: 50vw;
      height: 10vw;
    }

    .searchform img {
      width: 27.5vw;
    }

    .searchform {
      width: 80vw;

      margin-left: -40vw;
      margin-top: -22.5vw;
      font-size: 4vw;
    }
  }
`

const branch2 = ({ branchlist }) => {
  //topbtn create function
  if (typeof window !== `undefined`) {
    window.onscroll = function () {
      showButton()
    }
  }
  function showButton() {
    if (
      document.body.scrollTop > 50 ||
      document.documentElement.scrollTop > 50
    ) {
      document.getElementById("topbtn").className = "appear"
    } else {
      document.getElementById("topbtn").className = "disappear"
    }
  }
  // useEffect(() => {
  //   const script = document.createElement("script")
  //   script.async = true
  //   script.src =
  //     "https://dapi.kakao.com/v2/maps/sdk.js?appkey=4c52f2be0ec061241584c1d7c446aa49&autoload=false"
  //   document.head.appendChild(script)

  //   script.onload = () => {
  //     window.kakao.maps.load(() => {
  //       var mapContainer = document.getElementById("map"), // 지도의 중심좌표
  //         mapOption = {
  //           center: new window.kakao.maps.LatLng(
  //             37.43651040045741,
  //             126.80223796863781
  //           ), // 지도의 중심좌표
  //           level: 4, // 지도의 확대 레벨
  //         }

  //       var map = new window.kakao.maps.Map(mapContainer, mapOption) // 지도를 생성합니다

  //       let  positions = [

  //       ]

  //     })
  //   }
  // })
  const [searchdata, setSearchdata] = useState("")
  const [resultdata, setResultdata] = useState()
  let searchResultdata: any = []
  const cheageInput = (e: any) => {
    setSearchdata(e.value)
  }

  const searchReult = () => {
    searchResultdata.length = 0
    for (let i = 0; i < branchlist.length; i++) {
      if (branchlist[i].name.includes(searchdata)) {
        searchResultdata[i] = branchlist[i]
      }
    }
    setResultdata(searchResultdata)
  }
  return (
    <Container>
      {/* <div className="map-part">
        <div id="map" style={{ width: "60vw", height: "50vh" }} />
      </div> */}

      <div className="searchform">
        <input
          type="text"
          className="search-branch"
          placeholder="지점명 검색"
          onChange={({ target }) => cheageInput(target)}
        />
        <img src={Searchbtn} alt="검색버튼" onClick={searchReult} />
      </div>
      <Branch2_1 branchlist={resultdata ? resultdata : branchlist} />
      <div id="topbtn" onClick={() => window.scrollTo(0, 0)}>
        <FontAwesomeIcon icon={faAngleUp} />
        <p>up</p>
      </div>
    </Container>
  )
}

export default branch2
