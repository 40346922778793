import React from "react"
import Layout from "../components/Layout/Layout"
import styled from "styled-components"
import Branch1 from "components/branch/branch1"
import Branch2 from "components/branch/branch2"
import { graphql } from "gatsby"

const Container = styled.div`
  overflow-x: hidden;
`
const branchPage = ({ data }) => {
  const {
    allStrapiBranches: { nodes: branchlist },
  } = data

  return (
    <Container>
      <Layout>
        <Branch1 />
        <Branch2 branchlist={branchlist} />
      </Layout>
    </Container>
  )
}

export const query = graphql`
  {
    allStrapiBranches(sort: { fields: opendate, order: DESC }) {
      nodes {
        titlename
        name
        city
        address
        contact
        homepage
        image
        mapurl
        xpos
        ypos
        opendate
      }
    }
  }
`

export default branchPage
