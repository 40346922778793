import React, { useEffect } from "react"
import styled from "styled-components"
import arrow from "../../static/branches/arrowUnder.svg"

const Container = styled.div`
    .map-part div {
      margin: 0 auto;
    }

    .branch_image {
      width: 25vw;
      height:15vw;
      overflow: hidden
    }

    .branch_image img {
      width: 25vw;
      cursor:pointer;
      display: block;
    }

    .branch_list {
      display: flex;
      width: 60vw;
      margin 2vw auto;
      justify-content: space-between;
      align-items: center;
      box-shadow: 2px 1px 4px darkgrey;
      cursor:pointer;
    }

    .branch_list p {
      font-size: 1vw;
      line-height: 1.75;
    }

    .branch_text_part {
      width: 25vw;
      margin-right: 4vw;
      p{
        span{
          word-break: break-all;
        }
    }

    .branch_text_title {
      font-size: 1.2vw !important;
      font-weight: bold;
      margin-bottom: 0.7vw;   
     
      }
    }

    .show-480 {
      display: none;
    }

    @media screen and (max-width: 480px) {
      border-top: solid 3px #707070;
      width: 80vw;
      margin: 0 auto;
      padding: 0 0 8vw;
      .hide-480 {
        display: none;
      }

      .show-480 {
        display: block;
      }

       .branch-list-480 {
         display:flex;
         width: 80vw;
         margin: 0 auto;
         align-items: center;
         justify-content: space-between;
         padding: 2vw 0;
         border-bottom:solid 1px #d1d1d1;
       }

       .branchnames {
         width: 30vw;
        
       }

       .branchnames p {
        font-size: 3vw;
        line-height: 1.5;
        }

        .branchnames p:first-child{
          font-size: 4vw;
          font-weight: 500;
          }

          .branch-detail {
            display: none;
          }
       .branch-detail img {
        width: 80vw;
        display: block;
        margin: 3vw auto 0;
       }

       .branch-detail-text-part {
        width: 80vw;
        margin: 1vw auto 0;
        font-size: 4vw;
        font-weight: 500;
        line-height: 1.5;
        border-bottom:solid 1px #d1d1d1;
        padding: 3vw 0;
        p{
          span{
            word-break: break-all;
          }
        }
       }

       .choice-branch {
         display:block
       }

       .choice-detail {
         background-color:rgba(209, 209, 209, 0.29);
       }
    }
  `

const branch2_1 = ({ branchlist }) => {
  if (typeof window !== "undefined") {
    var detailzone = document.getElementsByClassName("branch-detail")
    var listzone = document.getElementsByClassName("branch-list-480")
  }
  // let btnVelue: number = 5

  const handleClick = (event: any) => {
    for (var i = 0; i < listzone.length; i++) {
      detailzone[i].classList.remove("choice-branch")
      listzone[i].classList.remove("choice-detail")
    }
    event.target.parentNode.classList.add("choice-detail")
    event.target.parentNode.nextSibling.classList.add("choice-branch")
  }

  return (
    <Container>
      {branchlist.map((list: any, index: number) => (
        <div>
          <div
            className="branch_list hide-480"
            onClick={() => window.open(`${list.mapurl}`, "_blank")}
          >
            <div className="branch_image">
              <img src={list.image} />
            </div>
            <div className="branch_text_part">
              <p className="branch_text_title">
                [{list.titlename} {list.name}]
              </p>
              <p>지역 : {list.city}</p>
              <p>지점명 : {list.name}</p>
              <p>주소 : {list.address}</p>
              <p>연락처 : {list.contact}</p>
              <p>
                홈페이지 : <span>{list.homepage}</span>
              </p>
            </div>
          </div>
          <div className="branch-list-480 show-480">
            <div className="branchnames">
              <p>{list.name}</p>
              <p>{list.titlename}</p>
            </div>
            <div>{list.city}시</div>
            <img
              className="underArrow"
              src={arrow}
              alt="내용보기 화살표"
              onClick={handleClick}
            />
          </div>
          <div className="branch-detail show-480">
            <img
              src={list.image}
              onClick={() => window.open(`${list.mapurl}`, "_blank")}
            />
            <div className="branch-detail-text-part">
              <p>지역 : {list.city}</p>
              <p>지점명 : {list.name}</p>
              <p>주소 : {list.address}</p>
              <p>연락처 : {list.contact}</p>
              <p>
                홈페이지 : <span>{list.homepage}</span>
              </p>
            </div>
          </div>
        </div>
      ))}
    </Container>
  )
}

export default branch2_1
